import { createStore } from "vuex";

import modules from "./modules";

const store = createStore({
  state: {
    baseUrl: process.env.NODE_ENV=='development' ? "http://localhost:5020/api/" : "https://hmf-survey.com/api/",
    mediaUrl: process.env.NODE_ENV=='development' ? "http://localhost:5020" : "https://hmf-survey.com",
    serverUrl: process.env.NODE_ENV=='development' ? "http://localhost:5020" : "https://hmf-survey.com",
   

    currentUser: {},
    token: null,
  },
  mutations: {
    SET_AUTHENTICATED(state, value) {
      state.authenticated = value;
    },
    SET_USER(state, value) {
      console.log(state.user);
      state.user = value;
    },
    addUserData(state, value) {
      state.currentUser = value;

      localStorage.setItem("user", JSON.stringify(value));
      console.log("userData :", state.currentUser);
    },
  },
  action: {
    changeLang() {


      if (localStorage.getItem("current_language") == "ar") {



        let bootstrap = document.querySelector('#bootstrap-lang-changer')
        bootstrap.setAttribute("href", "/css/bootstrap.rtl.min.css")
        setTimeout(() => {
          window.JsLoadingOverlay.hide();
        }, 300);

      } else {

        let bootstrap = document.querySelector('#bootstrap-lang-changer')
        bootstrap.setAttribute("href", "/css/bootstrap.min.css")

        setTimeout(() => {
          window.JsLoadingOverlay.hide();
        }, 300);

      }
    }
  },
  modules,
  // Enable strict mode in development to get a warning
  // when mutating state outside of a mutation.
  // https://vuex.vuejs.org/guide/strict.html
  strict: process.env.NODE_ENV !== "production",
});

export default store;
